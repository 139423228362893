/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue'
import { NavigationGuardNext, Route } from 'vue-router'

export type NavigationGuardContext = {
  to: Route
  from: Route
  next: NavigationGuardNext<Vue>
}

export type NavigationGuard = (ctx: NavigationGuardContext) => Promise<any> | any

export async function createMiddlewarePipeline(
  context: NavigationGuardContext,
  middlewares: NavigationGuard[]
) {
  const routerNext = context.next

  const executeMiddleware = async (index: number) => {
    if (index >= middlewares.length) {
      return routerNext()
    }

    const currentMiddleware = middlewares[index]

    context.next = (args?: any) => {
      if (args) {
        return routerNext(args)
      }

      return executeMiddleware(index + 1)
    }

    await currentMiddleware(context)
  }

  await executeMiddleware(0)
}

export function redirectTo(route: string) {
  return (({ next }) => {
    next(route)
  }) as NavigationGuard
}

export const authenticated: NavigationGuard = async ({ next }) => {
  return next()
}

export const unauthenticated: NavigationGuard = async ({ next }) => {
  const isLogged = true

  if (isLogged) {
    return next('/')
  }

  return next()
}

// export const paramsInitialization: NavigationGuard = async ({ to, next }) => {
//   const access_token = (to.query.state || "") as string;

//   if (!access_token) {
//     return next("401");
//   }

//   const language = decodeBase64(to.query.lang as string) as Language;

//   setQuasarLanguage(language, setQuasarLanguage);

//   setUserCache(access_token);

//   if (to.path === "/signinSz") {
//     return next("/");
//   }

//   return next();
// };

// export const pageLoading: (isLoading: boolean) => NavigationGuard =
//   (isLoading) =>
//   ({ store, from, next }) => {
//     if (!from.name) {
//       void store.dispatch("AuthModule/setLoading", isLoading);
//     }

//     return next();
//   };
