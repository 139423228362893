import { PluginContext } from '@/types'
import { decodeBase64 } from '@/utils/utils'
import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app'
import { Auth, getAuth } from 'firebase/auth'

let firebaseApp: FirebaseApp
let firebaseAuth: Auth

export default function ({ router }: PluginContext) {
  const firebaseConfigDecoded = decodeBase64(process.env.VUE_APP_FIREBASE_CONFIG || '')

  if (!firebaseConfigDecoded) {
    return router.replace('/401')
  }

  const firebaseConfigParsed: FirebaseOptions = JSON.parse(firebaseConfigDecoded)

  firebaseApp = initializeApp(firebaseConfigParsed)
  firebaseAuth = getAuth(firebaseApp)
}

export { firebaseApp, firebaseAuth }
