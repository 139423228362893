<script setup lang="ts">
import { useFavicon, useTitle } from '@vueuse/core'
import { computed, onBeforeMount, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { useSessionStore } from './store'
import useTranslate from './composables/useTranslate'

const router = useRouter()

const route = useRoute()

const translate = useTranslate()

const session = useSessionStore()

const currentRoute = computed(() => route.path.split('/')[1])

onBeforeMount(() => {
  const __route = useRoute()

  if (__route.hash === '') {
    router.replace({ path: route.path, hash: undefined, query: route.query }).catch(() => null)
  }
})

onMounted(() => {
  useFavicon(session.favicon)
})

watch(
  currentRoute,
  (newValue) => {
    useTitle(`${session.consumer?.name} | ${translate.value.common.pages_titles[newValue]}`)
  },
  { immediate: true }
)
</script>

<template>
  <div id="app">
    <router-view />
  </div>
</template>
