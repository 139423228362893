import { firebaseAuth } from '@/plugins/firebase'
import router from '@/router'
import { useSessionStore } from '@/store'
import axios, { AxiosResponse } from 'axios'

export function decodeBase64(base64: string) {
  if (base64) {
    const buff = Buffer.from(base64, 'base64')

    return buff.toString('utf8')
  }

  return null
}

export function signOut() {
  const session = useSessionStore()

  session.user = null

  firebaseAuth.signOut()

  router.replace({
    path: '/sign-in',
    query: router.currentRoute.query
  })
}

export async function redirectToOrigin() {
  const session = useSessionStore()

  if (session.user?.email && session.product?.integration_url && session.token) {
    await axios
      .post(session.product.integration_url, {
        email: session.user.email,
        token: session.token
      })
      .then((res: AxiosResponse<{ url: string }>) => {
        if (res.data?.url) {
          window.location.replace(res.data.url)
        }
      })
  }
}
