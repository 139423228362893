import { ApiType, createApi } from '@/services/plugin'

import { AuthenticationResponse, OAuth, OAuthLocalStorage } from '@/services/oauth'
import { PluginContext } from '@/types'
import axios from 'axios'
import { useSessionStore } from '@/store'

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const baseURL = process.env.VUE_APP_API!

let api: ApiType

let oauth: OAuth<AuthenticationResponse>

const storage = new OAuthLocalStorage()

export default function ({ router }: PluginContext) {
  const sourceHost = router.currentRoute.query['source_host'] as string
  const productId = router.currentRoute.query['product_id'] as string

  if (!sourceHost || !productId) {
    return router.replace('/404')
  }

  const session = useSessionStore()

  session.productId = productId

  const axiosBaseURL = `${baseURL}/auth/signin`

  axios.defaults.headers.common['Source-Host'] = sourceHost

  axios.defaults.headers.common['Product-Id'] = productId

  oauth = new OAuth<AuthenticationResponse>({
    tokenUrl: axiosBaseURL,
    storage
  })

  api = Object.freeze(
    createApi<AuthenticationResponse>({
      defaultEndpoint: baseURL,
      router,
      oauth
    })
  )
}

export { api, oauth, storage }
