const routes = {
  auth: {
    signin: '/sign-in',
    forgot: '/forgot',
    new_password: '/new-password',
    two_fa: '/2fa'
  },
  contact_support: {
    root: '/contact-support'
  }
}

export default routes
