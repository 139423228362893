import { computed } from 'vue'
import { i18n } from '../plugins/i18n'
import useTranslateSzComponents from '@sz4/sz-components/src/composables/useTranslate'

export default function useTranslate() {
  const { translate: szComponentsTranslate } = useTranslateSzComponents()

  const translate = computed(() => ({
    lang: {
      ptbr: i18n.t('authenticator.lang.ptbr'),
      es: i18n.t('authenticator.lang.es'),
      en: i18n.t('authenticator.lang.en')
    },
    common: {
      language: i18n.t('authenticator.common.language'),
      forgot_your_password: i18n.t('authenticator.common.forgot_your_password'),
      signin_email: i18n.t('authenticator.common.signin_email'),
      back_to_signin: i18n.t('authenticator.common.back_to_signin'),
      password: i18n.t('authenticator.common.password'),
      password_confirm: i18n.t('authenticator.common.password_confirm'),
      or: i18n.t('authenticator.common.or'),
      recommended: i18n.t('authenticator.common.recommended'),
      actions: {
        signin: i18n.t('authenticator.common.actions.signin'),
        validate: i18n.t('authenticator.common.actions.validate'),
        reset_password: i18n.t('authenticator.common.actions.reset_password'),
        signin_with: (target: string) =>
          i18n.t('authenticator.common.actions.signin_with', { target })
      },
      pages_titles: {
        'sign-in': i18n.t('authenticator.common.pages_titles.sign-in'),
        '2fa': i18n.t('authenticator.common.pages_titles.2fa')
      }
    },
    messages: {
      invalid_code: i18n.t('authenticator.common.messages.invalid_code')
    },
    pages: {
      two_fa: {
        common: {
          two_step_verification: i18n.t('authenticator.pages.two_fa.common.two_step_verification')
        },
        verification_method: {
          subtitle: i18n.t('authenticator.pages.two_fa.verification_method.subtitle'),
          enum: {
            mfas: {
              email: {
                title: i18n.t(
                  'authenticator.pages.two_fa.verification_method.enum.mfas.email.title'
                ),
                description: (accountName: string) =>
                  i18n.t(
                    'authenticator.pages.two_fa.verification_method.enum.mfas.email.description',
                    { accountName }
                  )
              },
              totp: {
                title: i18n.t(
                  'authenticator.pages.two_fa.verification_method.enum.mfas.totp.title'
                ),
                description: (accountName: string) =>
                  i18n.t(
                    'authenticator.pages.two_fa.verification_method.enum.mfas.totp.description',
                    { accountName }
                  )
              }
            }
          }
        },
        pre_code_check: {
          email: {
            an_email_will_be_sent: i18n.t(
              'authenticator.pages.two_fa.pre_code_check.email.an_email_will_be_sent'
            ),
            we_will_send_a_link_to_email: i18n.t(
              'authenticator.pages.two_fa.pre_code_check.email.we_will_send_a_link_to_email'
            )
          },
          totp: {
            instead_of_waiting_for_text_messages: i18n.t(
              'authenticator.pages.two_fa.pre_code_check.totp.instead_of_waiting_for_text_messages'
            ),
            first_download_the_app: i18n.t(
              'authenticator.pages.two_fa.pre_code_check.totp.first_download_the_app'
            )
          }
        },
        code_checker: {
          title: i18n.t('authenticator.pages.two_fa.code_check.title'),
          subtitle: {
            mail: (target: string) =>
              i18n.t('authenticator.pages.two_fa.code_check.subtitle.mail', { target })
          },
          may_take_few_minutes: i18n.t(
            'authenticator.pages.two_fa.code_check.may_take_few_minutes'
          ),
          have_not_received: i18n.t('authenticator.pages.two_fa.code_check.have_not_received'),
          time_left_to_resend_code: (time?: string | number) =>
            i18n.t('authenticator.pages.two_fa.code_check.time_left_to_resend_code', { time }),
          keep_logged: i18n.t('authenticator.pages.two_fa.code_check.keep_logged'),
          send_again: i18n.t('authenticator.pages.two_fa.code_check.send_again')
        },
        confirmation: {
          title: i18n.t('authenticator.pages.two_fa.confirmation.title'),
          subtitle: i18n.t('authenticator.pages.two_fa.confirmation.subtitle')
        }
      }
    },
    ...szComponentsTranslate.value
  }))

  return translate
}
