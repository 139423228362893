import { firebaseAuth } from '@/plugins/firebase'
import { multiFactor, TotpMultiFactorGenerator } from 'firebase/auth'
import { defineStore } from 'pinia'
import { useSessionStore } from './session'
import { Totp } from '@/composables/useMFA'

export const useMFAStore = defineStore('mfas', {
  state: () => ({
    code: {
      value: '',
      error: ''
    },
    totp: {
      secret: null,
      uri: null
    } as Totp
  }),
  getters: {
    list() {
      const session = useSessionStore()

      return session.consumer?.config.mfa
    }
  },
  actions: {
    async genTotpData() {
      const currentUser = firebaseAuth.currentUser

      if (currentUser && this.list) {
        const multiFactorSession = await multiFactor(currentUser).getSession()

        this.totp.secret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession)

        this.totp.uri = this.totp.secret.generateQrCodeUrl(
          currentUser.email || '',
          this.list.totp.mfaDisplayName
        )
      }
    }
  }
})
