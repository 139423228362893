import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { PluginContext } from './types'

import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/ionicons-v4/ionicons-v4.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/mdi-v5/mdi-v5.css'

import 'quasar/dist/quasar.min.css'
import '@sz4/sz-components/src/css/app.scss'
import './css/app.scss'
import { createPinia, PiniaVuePlugin } from 'pinia'

Vue.config.productionTip = false

const plugins = [
  'firebase',
  'quasar',
  'sz-components',
  'global-components',
  'i18n',
  'services',
  'context'
] as const

Vue.use(PiniaVuePlugin)

const pinia = createPinia()

const app = new Vue({
  router,
  pinia,
  render: (h) => h(App)
})

async function loadPlugins() {
  for (const plugin of plugins) {
    const { default: defaultImport } = await import(`./plugins/${plugin}`)

    const fn: (ctx: PluginContext) => Promise<void> | void =
      defaultImport || (() => Promise.resolve(undefined))

    await fn({ Vue, app, router })
  }
}

loadPlugins().finally(() => {
  app.$mount('#app')
})
