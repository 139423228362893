import { useSessionStore } from '@/store'
import { computed } from 'vue'

export function getLogo() {
  const session = useSessionStore()

  const logo = computed(() => {
    return session.brand
  })

  return { logo }
}

export function parseJWT<T>(token: string): T {
  const base64Url = token.split('.')[1]

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')

  const buff = Buffer.from(base64, 'base64')

  const payloadinit = buff.toString('ascii')

  return JSON.parse(payloadinit)
}
