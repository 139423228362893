import Vue from 'vue'
import VueRouter from 'vue-router'
import { NavigationGuard } from './middlewares'
import routes from './routes'

Vue.use(VueRouter)

function buildMiddlewaresQueue(middlewareMeta: NavigationGuard | NavigationGuard[]) {
  return Array.isArray(middlewareMeta) ? middlewareMeta : [middlewareMeta]
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const parentMiddlewaresQueue = buildMiddlewaresQueue(to.matched[0]?.meta?.middleware || [])

  if (to?.meta?.middleware || parentMiddlewaresQueue.length) {
    const middlewaresQueue: NavigationGuard[] = buildMiddlewaresQueue(
      to?.meta?.middleware || []
    ).concat(parentMiddlewaresQueue)

    for (const middleware of middlewaresQueue) {
      try {
        await new Promise((resolve) => {
          resolve(middleware({ to, from, next }))
        })
      } catch (error) {
        console.error('Middleware execution failed:', error)

        return next('/error')
      }
    }

    return
  }

  return next()
})

export default router
