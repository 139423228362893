import { firebaseAuth } from '@/plugins/firebase'
import { api } from '@/plugins/services'
import { Consumer, FullUser } from '@/services/models'
import useWhiteLabel from '@sz4/sz-components/src/composables/useWhiteLabel'
import { defineStore } from 'pinia'

export const useSessionStore = defineStore('session', {
  state: () => ({
    user: null as null | FullUser,
    consumer: null as null | Consumer,
    token: null as null | string,
    productId: ''
  }),
  getters: {
    brand(state) {
      return state.consumer?.layout.brand
    },
    favicon(state) {
      return state.consumer?.layout.favicon || 'loading.svg'
    },
    accountName(state) {
      return state.consumer?.name
    },
    product(state) {
      return state.consumer?.products.find((product) => product.id === state.productId)
    }
  },
  actions: {
    async getContext() {
      this.consumer = await api.consumers.getContext()

      firebaseAuth.tenantId = this.consumer.tenant_id

      useWhiteLabel(this.consumer.layout.theme)
    },
    getUser() {
      this.user = {
        _id: '',
        email: '',
        name: '',
        password: '',
        photo: '',
        type: '',
        created_at: '',
        updated_at: ''
      }
    },
    clear() {
      this.consumer = null
    }
  }
})
