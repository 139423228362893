import routesPaths from '@/utils/routes'
import { RouteConfig } from 'vue-router'
import { redirectTo } from './middlewares'

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'root',
    meta: { middleware: redirectTo('/404') }
  },
  {
    path: routesPaths.auth.signin,
    component: () => import('@/layouts/Auth.vue'),
    children: [
      {
        path: '',
        name: 'signin',
        component: () => import('@/pages/Signin.vue')
      }
    ]
  },
  {
    path: routesPaths.auth.forgot,
    component: () => import('@/layouts/Auth.vue'),
    children: [
      {
        path: '',
        name: 'forgot',
        component: () => import('@/pages/Forgot.vue')
      }
    ]
  },
  {
    path: routesPaths.auth.two_fa,
    component: () => import('@/layouts/Auth.vue'),
    children: [
      {
        path: '',
        name: 'two_fa',
        component: () => import('@/pages/TwoFa/Index.vue')
      },
      {
        path: 'confirm-code',
        name: 'confirm_code',
        component: () => import('@/pages/TwoFa/ConfirmCode.vue')
      }
    ]
  },
  {
    path: '/confirm-code',
    component: () => import('@/layouts/Auth.vue'),
    children: [
      {
        path: '',
        name: 'confirm__code',
        component: () => import('@/pages/TwoFa/ConfirmCode.vue')
      }
    ]
  },
  {
    path: routesPaths.auth.new_password,
    component: () => import('@/layouts/Auth.vue'),
    children: [
      {
        path: '',
        name: 'new-password',
        component: () => import('@/pages/NewPassword.vue')
      }
    ]
  },
  {
    path: routesPaths.contact_support.root,
    component: () => import('@/layouts/Auth.vue'),
    children: [
      {
        path: '',
        name: 'contact-support',
        component: () => import('@/pages/ContactSupport.vue')
      }
    ]
  },
  {
    path: '/test',
    component: () => import('@/layouts/Test.vue'),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/pages/Test/HomeView.vue')
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('@/pages/Test/About.vue')
      }
    ]
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/pages/Errors/403.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/pages/Errors/404.vue')
  }
]

export default routes
