import { PluginContext } from '@/types'
import { setQuasarLanguage, Language } from '@sz4/sz-components/src/composables/useTranslate'
import { nextTick } from 'vue'
import VueI18n, { I18nOptions } from 'vue-i18n'

const SUPPORTTED_LOCALES = ['pt-BR', 'es', 'en'] as const

let i18n: VueI18n

/**
 * Carrega o novo idioma para i18n.
 * @param i18n - Instância do VueI18n.
 * @param locale - Idioma que será carregado.
 * @returns
 */
async function loadAndSetLocale(i18n: VueI18n, locale: string): Promise<void> {
  if (!SUPPORTTED_LOCALES.includes(locale as Language)) {
    locale = 'en'
  }

  if (!i18n.availableLocales.includes(locale)) {
    const messages = await import(`../locales/${locale}.json`).then((i) => i.default)

    i18n.setLocaleMessage(locale, messages)
  }

  i18n.locale = locale

  setQuasarLanguage(locale as Language)

  return nextTick()
}

/**
 * Setup da instância do VueI18n.
 * @param options - Opções para inicilização do VueI18n.
 * @returns
 */
async function setupI18n(options: I18nOptions = {}) {
  options = Object.assign<I18nOptions, I18nOptions>({ fallbackLocale: 'en' }, options)

  const i18nInstance = new VueI18n(options)

  const locale = window.navigator.language

  await loadAndSetLocale(i18nInstance, locale)

  return i18nInstance
}

/**
 * Método que modifica o idioma atual.
 * @param locale - Idioma que será lido.
 */
async function setI18nLanguage(locale: string) {
  await loadAndSetLocale(i18n, locale)
}

export default async function ({ Vue, app }: PluginContext) {
  i18n = await setupI18n()

  Vue.use(VueI18n)

  app.i18n = i18n
}

export { i18n, setI18nLanguage, SUPPORTTED_LOCALES }
